@import "../../shared/core/variables.scss";

.reset-container {
    height: 100vh;
    margin: 0 auto;
    align-self: center;
    width: 80%;
    text-align: center;
    font-family: $secondaryFont;
    display: grid;
    grid-template-rows: auto min-content;
    .paper {
        padding: 20px 50px;
        margin: 20px auto;
        width: 500px;
        height: min-content;
        img {
            margin-bottom: 20px;
            height: 70px;
        }

        .title {
            margin: 10px auto;
            padding: 0px 80px;
            width: max-content;
            font-family: $primaryFont !important;
            font-size: 36px;
            font-weight: 300;
        }

        .login-form {
            text-align: -webkit-center;
            margin-bottom: 10px;
            .form-item {
                width: 250px;
                margin-bottom: 10px;
                .name {
                    margin: 5px 0px;
                    text-align: left;
                    font-weight: 900;
                    font-size: 10px;
                }
                .MuiOutlinedInput-root {
                    height: 40px;
                    border-radius: 9px !important;
                    border-width: 10px !important;
                }
                .MuiOutlinedInput-adornedStart {
                    padding-left: 0px;
                }
            }
        }

        .actions {
            margin: 20px;
            .MuiButton-root {
                padding: 10px 90px;
                border-radius: 9px !important;
            }
        }
    }
    .bottom {
        .back {
            visibility: visible !important;
        }
    }

    .MuiDivider-root {
        background-color: rgba(0, 0, 0, 0.2) !important;
    }
    .success-message {
        color: green;
    }
}
