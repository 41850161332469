@import "../../shared/core/variables.scss";

.order-details-container {
    .top-bar {
        padding: 0px 20px;
    }

    .section {
        background-color: #f0eef2;
        width: 100%;
        height: calc(100vh - 80px);
        display: grid;
        overflow: hidden;
        padding: 0px 40px;
        padding-bottom: 40px;

        .back-button {
            padding: 20px 0;
            display: flex;
            justify-content: space-between;
            .MuiButton-root {
                font-size: 12px;
                padding: 5px 20px;
                padding-right: 18px !important;
                border-radius: 15px !important;
            }
        }

        .table-section {
            width: 100%;
            margin-bottom: 20px;

            .MuiTableCell-head {
                color: $primaryColor;
            }

            .MuiTableContainer-root {
                height: 70vh;
                overflow-x: auto;
            }

            .head-item {
                display: grid;
                grid-template-columns: min-content max-content;
                align-items: center;

                .title {
                    padding-left: 5px;
                }
            }
        }
    }

    .header {
        background: white;
        padding: 20px;
        font-size: 1.3em;
        font-weight: bold;
        border-bottom: 1px solid #dfdfdf;

        display: grid;
        grid-template-columns: auto min-content;

        .MuiButton-root {
            border-radius: 20px;
            height: 35px;
            white-space: nowrap;
        }
    }

    .cart {
        background: white;
        overflow: auto;
        .orderItems {
            height: calc(80vh - 140px);
            overflow: auto;

            .orderItem {
                border: 1px solid #dfdfdf;
                padding: 10px;
                margin: 5px;
                display: grid;
                grid-template-columns: 100px auto min-content;

                .image {
                    height: 100px;
                    background-size: cover;
                    background-position: center;
                }

                .details {
                    padding: 10px;

                    .label {
                    }

                    .subLabel {
                    }

                    .restaurant {
                        font-size: 1.2em;
                        font-weight: bold;
                        color: $subtitleColor;
                    }

                    .ingredients {
                        margin-top: 5px;
                        margin-bottom: 2px;
                        display: grid;
                        grid-template-columns: max-content auto;
                        align-items: center;

                        .items {
                            display: flex;
                            flex-flow: row wrap;
                            justify-content: flex-start;

                            .chip {
                                width: fit-content;
                                margin-left: 2px;
                                margin-bottom: 2px;
                            }
                        }
                    }
                }

                .price {
                    align-self: center;
                }
            }
        }

        .footer {
            border-top: 1px solid #dfdfdf;
            padding: 20px;
            .footerItem {
                display: grid;
                grid-template-columns: auto min-content;
                margin-bottom: 5px;
            }
        }
    }
}

#head {
    font-weight: 800;

    .head-item {
        display: grid;
        grid-template-columns: min-content max-content;
        align-items: center;

        .MuiSvgIcon-root {
            width: 0px;
            color: $primaryColor;
            visibility: hidden;
        }
    }
}

#error-row {
    border: solid;
    border-width: 2px;
    border-color: $primaryColor;

    .head-item {
        .MuiSvgIcon-root {
            width: auto !important;
            visibility: visible !important;
        }
    }
}

.issueModalContainer{
    .textInput{
        width: 100% !important;
    }
}
