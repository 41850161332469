@import "../../../shared/core/variables.scss";

.kitchen-order-list-container {
    .section {
        background-color: #f0eef2;
        width: 100%;
        min-height: 85vh;
        padding: 40px;

        .heading{
            display: grid;
            grid-template-columns: auto min-content min-content min-content;
            font-size: 30px;
            font-weight: 800;

            button {
                margin-right: 15px;
            }

            .time{
                text-align: right;
                margin-right: 20px;
            }
        }

        .status{
            padding: 20px;
            .state {                
                height: 120px;
                width: 100%;           
                text-align: center;
                color: #fff;
                border-radius: 3px;
                box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
                .name {
                    padding-top: 10px;
                    font-size: 16px;
                    font-weight: 800;
                }
                .count{
                    height: fit-content;
                    font-size: 60px;
                    font-weight: 800;
                }
            }
        }

        .table-section {
            width: 100%;
            .MuiTableCell-head {
                color: $primaryColor;
            }

            .MuiTableContainer-root {
                overflow-x: auto;
                max-height: 500px;

                .MuiTableBody-root {
                    .MuiChip-root {
                        margin: 2px;
                    }
                    .new{
                        .MuiTableCell-root {
                            background-color: #EC7063;
                        }
                    }

                    .ack{
                        .MuiTableCell-root {
                            background-color: #F8C471 ;
                        }
                    }

                    .prep{
                        .MuiTableCell-root {
                            background-color: #52BE80 ;
                        }
                    }

                    .ready{
                        .MuiTableCell-root {
                            background-color: #3498DB ;
                        }
                    }
                }
            }

            .head-item {
                display: grid;
                grid-template-columns: min-content max-content;
                align-items: center;

                .title {
                    padding-left: 5px;
                }
            }
        }
    }
}
