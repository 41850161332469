@import "../../../shared/core/variables.scss";

.kitchen-order-item-container {
    .section {
        background-color: #f0eef2;
        width: 100%;
        min-height: 85vh;
        padding: 40px;

        .heading{
            display: grid;
            grid-template-columns: 30% auto 30%;
            font-size: 30px;
            font-weight: 800;
            padding-bottom: 20px;

            .order-no{
                text-align: center;
            }

            .time{
                text-align: right;
            }
        }

        .middle-bar{
            padding: 10px 0px;
            display: grid;
            grid-template-columns: 30% 70%;
            align-items: center;
            font-size: 20px;
            font-weight: 800;

            .add-time-container{
                margin-left: 10px;
                display: grid;
                grid-template-columns: 30% 70%;
                align-items: center;

                .add-time-button-list{
                    display: grid;
                    grid-template-columns: auto auto auto auto auto auto auto auto;
                    .MuiButton-root {
                        border-radius: 5px !important;
                        max-width: 90%;
                    }
                }
            }
        }

        .order-body{
            padding: 10px 0px;
            display: grid;
            grid-template-columns: 30% auto;

            .summery{
                border-style: solid;
                border-width: thin;
                padding: 15px 10px;
                border-color: rgba(0, 0, 0, 0.12);
                height: 400px;

                .times{
                    display: grid;
                    grid-template-columns: auto auto;
                    margin-bottom: 20px;

                    .name{
                        font-weight: bold;
                        text-align: left;
                    }

                    .time{
                        text-align: right;
                    }

                    .current-state{
                        font-weight: bold;
                        text-align: right;

                        .new{
                            color: #EC7063;
                        }
    
                        .ack{
                            color: #F8C471 ;
                        }
    
                        .prep{
                            color: #52BE80 ;
                        }
    
                        .ready{
                            color: #3498DB ;
                        }
                    }
                }
                .action {
                    margin: 20px;
                    text-align: center;
                    .MuiButton-root {
                        padding: 10px;
                        border-radius: 9px !important;
                        max-width: 90%;
                        min-width: 90%;
                    }

                        .new{
                            .MuiButton-root {
                                background-color: #EC7063;
                            }
                        }
    
                        .ack{
                            .MuiButton-root {
                                background-color: #F8C471 ;
                            }
                        }
    
                        .prep{
                            .MuiButton-root {
                                background-color: #52BE80 ;
                            }
                        }
    
                        .ready{
                            .MuiButton-root {
                                background-color: #3498DB ;
                            }
                        }
                }

                .count{
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;

                    .count-time{
                        margin-top: 10px;
                        font-size: 23px;
                    }
                }
            }

            .details{
                margin-left: 10px;
                padding: 5px;
                border-style: solid;
                border-width: thin;
                border-color: rgba(0, 0, 0, 0.12);
                overflow: auto;
                height: 400px;

                .single-item{
                    padding: 10px 0px 10px 10px;
                }
            }
        }

        .back{
            margin: 20px 0px;
        }

    }
}
