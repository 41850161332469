.container {
    .header {
        display: grid;
        align-items: center;
        grid-template-columns: min-content min-content;
        font-weight: bold;

        padding: 10px 20px;
        border-bottom: 1px solid #dddddd;
        font-size: 1.2em;
        white-space: nowrap;

        img {
            height: 20px;
            margin-right: 15px;
        }
    }

    .content {
        padding: 40px 5%;

        .title {
            font-weight: bold;
            font-size: 1.2em;
        }

        .deletedOrder{
            background-color: rgb(230, 152, 152) !important;
        }

        table {
            width: 100%;
            border-spacing: 0 10px;
            font-size: 0.9em;

            tr{
                background: white;
            }

            th {
                text-align: left;
                padding: 5px;
                top: 0;
                position: sticky;
                background: #bcbcbc;
            }

            td {
                // background: white;
                padding: 10px;
            }

            .actions {
                button {
                    margin-right: 10px;

                    img {
                        height: 20px;
                    }
                }
            }
        }
    }
}
