.modalContainer{
    .subjectText{
        -webkit-font-smoothing: antialiased;
        font-family: "Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
        font-size: 1.375rem;
        font-variant-ligatures: no-contextual;
        color: #1f1f1f;
        font-weight: 400;
        line-height: normal;
    }
    .customerText{
        font-size: 1rem;
        
    }
    .messageText{
        font-size: 16px;
        line-height: 24px;
    }
    .msgHead{
        font-size: 16px;
        line-height: 24px;
        margin-top: 1rem;
    }
    .replyBox{
        margin-top:2rem
    }
    .headingContainer{
        display: flex;
        justify-content: space-between;
    }

    .date{
        font-size: 12px;
        font-weight: 600;
        color: #808080;
    }
}