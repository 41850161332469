@import "../../shared/core/variables.scss";

@media only screen and (max-width: 1440px) {
    //laptop
    .order-item-container {
        .section {
            .child {
                .left {
                    .form {
                        .item {
                            .item-action {
                                .MuiFormControl-root {
                                    width: 250px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1440px) {
    //desktop
    .order-item-container {
        .section {
            .child {
                .left {
                    .form {
                        .item {
                            .item-action {
                                .MuiFormControl-root {
                                    width: 300px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.order-item-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto max-content;
    height: 100vh;

    .section {
        padding: 0px 40px;
        overflow: auto;
        .child {
            display: grid;
            grid-template-columns: 60% 40%;
            .left {
                margin-right: 10px;
                .title {
                    text-align: start;
                    font-size: 36px;
                    font-weight: 300;
                    font-family: $secondaryFont;
                    padding: 20px 0px;
                }

                .subtitle {
                    text-align: start;
                    width: 90%;
                    font-size: 14px;
                    font-weight: 500;
                }

                .form {
                    align-content: left;
                    margin: 20px 0px;
                    padding-right: 50px;
                    .item {
                        align-items: center;
                        text-align: start;
                        display: grid;
                        grid-template-columns: 25% 75%;
                        margin-bottom: 10px;
                        font-weight: 500;

                        .item-action {
                            width: 75%;
                            .MuiOutlinedInput-root {
                                border-radius: 15px;
                            }

                            .MuiOutlinedInput-input {
                                padding: 10px;
                            }

                            .price {
                                font-size: 17px;
                                font-weight: 700;
                                color: $subtitleColor;
                            }

                            .count {
                                .MuiOutlinedInput-root {
                                    width: 135px;
                                }

                                .MuiOutlinedInput-input {
                                    padding: 10px 0px;
                                }

                                .MuiInputBase-input {
                                    text-align: center;
                                    width: min-content;
                                }
                                .MuiOutlinedInput-adornedEnd {
                                    padding-right: 0px;
                                }

                                .MuiOutlinedInput-adornedStart {
                                    padding-left: 0px;
                                }

                                .MuiOutlinedInput-root {
                                    border-radius: 30px !important;
                                }

                                .PrivateNotchedOutline-root-1 {
                                    border-width: 3px !important;
                                    border-radius: 30px !important;
                                }
                            }
                        }
                    }
                }
                .actions {
                    padding-top: 20px;
                    display: grid;
                    grid-template-columns: max-content max-content;

                    .simple {
                        .MuiButton-root {
                            width: 100%;
                            font-size: 12px;
                            padding: 10px 140px;
                            border-radius: 30px;
                        }
                    }
                }

                .login-warning {
                    margin-top: 5px;
                    color: $primaryColor;
                }
            }

            .right {
                text-align: end;
                .back {
                    padding: 20px 0px;
                    .back {
                        .MuiButton-root {
                            font-size: 12px;
                            padding: 5px 20px;
                            padding-right: 18px !important;
                            border-radius: 15px !important;
                        }
                    }
                }

                img {
                    width: 100%;
                    border-radius: 10px;
                }
            }
        }

        .footer {
            margin-top: 20px;
            .bottom {
                .back {
                    visibility: hidden !important;
                }
            }
        }
    }
}
