@import "../../shared/core/variables.scss";

.add-order-item-container {
    .section {
        background-color: #f0eef2;
        width: 100%;
        padding: 40px;
        .MuiPaper-root {
            padding: 20px;
            min-height: 85vh;
        }

        .back {
            .MuiButton-root {
                font-size: 12px;
                padding: 5px 20px;
                padding-right: 30px !important;
                border-radius: 30px !important;
            }
        }

        .title {
            text-align: center;
            font-size: 34px;
            font-weight: 400;
        }

        .form-section {
            margin: 20px auto;
            width: fit-content;
            min-width: 650px;
            height: 100%;
            padding: 20px 80px;
            border: solid;
            border-width: 1px;
            border-radius: 10px;
            border-color: rgba($color: $subtitleColor, $alpha: 0.4);

            .row {
                align-items: center;
                display: grid;
                grid-template-columns: 30% 70%;
                text-align: start;
                margin-bottom: 20px;

                .left {
                    padding-top: 10px;
                    align-self: start;
                    text-align: end;
                    font-size: 12px;
                    font-weight: 600;
                    margin-right: 20px;
                }

                .right {
                    .multiline-textbox {
                        .MuiOutlinedInput-root {
                            height: auto !important;
                            width: 100% !important;
                        }
                    }
                    .action {
                        display: grid;
                        grid-template-columns: max-content max-content;
                        align-items: center;
                        .simple {
                            .MuiButton-root {
                                font-size: 12px;
                                padding: 5px 40px;
                                border-radius: 30px;
                            }
                        }

                        .cancel {
                            cursor: pointer;
                            padding-left: 10px;
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                }
                .MuiFormControl-root {
                    padding-right: 20px;
                }
                .MuiFormControl-root {
                    min-width: 100%;
                    padding-right: 20px;
                }
                .MuiOutlinedInput-root {
                    border-radius: 10px;
                }

                .MuiOutlinedInput-input {
                    padding: 10px;
                }
                .MuiOutlinedInput-root {
                    width: 30%;
                    height: 40px;
                    border-radius: 9px !important;
                    border-width: 10px !important;
                }
            }
        }
    }
}
