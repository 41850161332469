@import "../core/variables.scss";

@media only screen and (max-width: 1440px) {
    //laptop
    .top-bar-container {
        .top-bar {
            .logo {
                img {
                    height: 40px;
                }
            }
            .simple {
                .MuiButton-root {
                    min-width: 95px;
                }
            }
        }
        .title {
            font-size: 34px;
            font-weight: 400;
            margin-right: 20px;
            margin-left: 20px;
        }
    }
}

@media only screen and (min-width: 1440px) {
    //desktop
    .top-bar-container {
        .top-bar {
            .logo {
                img {
                    height: 60px;
                }
            }
            .simple {
                .MuiButton-root {
                    min-width: 115px;
                }
            }
        }
    }
}

.top-bar-container {
    height: 80px;

    .top-bar {
        width: 100%;
        height: 60px;
        margin: 20px 0px;
        display: grid;
        align-items: center;
        grid-template-columns: min-content min-content auto max-content min-content min-content;

        .mailCount{
            cursor: pointer;
            margin-left: 0.5rem;
        }

        .title {
            font-size: 27px;
            font-weight: 400;
            margin-right: 20px;
            margin-left: 20px;
        }

        .logo {
            text-align: start;
            margin-right: 10px;
        }

        .search-bar {
            visibility: visible;
        }

        .simple {
            margin-left: 10px;
            display: grid;
            grid-template-columns: min-content min-content;
            .MuiButton-root {
                font-size: 12px !important;
                font-weight: bold !important;
                padding: 5px 20px !important;
                border-radius: 30px !important;
            }
            :last-child {
                .MuiButton-root {
                    padding: 5px 30px !important;
                }
            }
            .containedPrimary {
                color: $subtitleColor;
                background-color: #fbfbfb;
            }
        }

        .options {
            margin-left: 10px;
            text-align: end;
            img {
                height: 20px;
            }
        }
    }

    .MuiDrawer-paper {
        min-width: 300px;
        align-content: space-between;
        display: grid;

        .no-login {
            padding: 20px;
            color: $subtitleColor;
            text-align: center;
        }

        .logout {
            margin: 20px;
            .MuiButton-root {
                padding: 10px 90px;
                border-radius: 9px !important;
            }
        }
    }

    // .MuiDrawer-paperAnchorRight {
    //     right: 350px !important;
    // }
}
