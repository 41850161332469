@import "../../shared/core/variables.scss";

.container {
    display: grid;
    //grid-template-columns: auto max-content;

    .cancelBtn {
        font-size: 14px;
        padding: 2px 10px;
        padding-right: 15px !important;
        border-radius: 15px !important;
        margin-bottom: 15px;
    }

    .section {
        padding: 0px 0px;
        height: 100vh;
        overflow: hidden;

        .body {
            .form {
                width: 70%;
                margin: 20px 0px;

                .title {
                    font-family: $primaryFont !important;
                    font-size: 36px;
                    font-weight: 400 !important;
                    margin-bottom: 20px;
                }

                .formGroup {
                    .formField {
                        .fieldTitle {
                            font-weight: bold;
                            margin-left: 5px;
                            margin: 10px 0;
                        }

                        .formControl {
                            margin-top: 10px;
                        }
                    }

                    .row2 {
                        margin-top: 10px;
                        display: grid;
                        grid-template-columns: 160px 100px;

                        .formField {
                            margin-right: 15px;
                        }

                        .expiryDate {
                            display: grid;
                            grid-template-columns: 100px 100px;

                            .formControl {
                                margin-top: 0;
                                margin-right: 10px;
                            }
                        }
                    }

                    .doubleRow {
                        display: grid;
                        grid-template-columns: 50% 50%;

                        .formField {
                            margin-right: 15px;
                        }
                    }
                }

                .errorAlert {
                    margin-top: 20px;
                    color: $primaryColor;
                    font-weight: bold;
                }

                .actions {
                    margin-top: 20px;
                    text-align: right;
                    border-top: 1px solid #e4e4e4;
                    padding-top: 20px;

                    button {
                        font-size: 12px;
                        padding: 8px 50px;
                        border-radius: 30px;
                    }
                }
            }
        }
    }
    .back {
        margin: 5px;
        margin-left: 4%;
    }
}

.displayBackButton{
    display: block;
    padding: 16px 24px;
}

@media only screen and (min-width: 1000px) {
    .container{
        width: 70%;
        margin: auto;
        margin-top: 2%;
        .section{
            padding: 0px 40px;
            height: 100vh;
            overflow: hidden;
        }
        .back {
            margin: 5px;
            margin-left: 4%;
        }
    }
}
// @media only screen and (max-width: 500px) {
//     .displayBackButton{
//         display: none;
//     }
// }