@import "../core/variables.scss";

.sideMenuItem {
    margin-bottom: 20px;
    .MuiPaper-root {
        padding: 10px;
        .item-name-row {
            display: grid;
            grid-template-columns: 50% 50%;
            align-items: center;
            .item-name {
                text-align: start;
                font-size: 12px;
                font-weight: 600;
            }
            .remove {
                text-align: end;
            }
        }

        .subtitle {
            text-align: start;
            color: $subtitleColor;
            width: 90%;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 4px;
        }

        .product-size {
            margin-bottom: 2px;
        }

        .preferences {
            margin-bottom: 2px;
        }

        .ingredients {
            margin-bottom: 2px;
            display: grid;
            grid-template-columns: max-content auto;

            .items {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;

                .MuiChip-sizeSmall {
                    width: fit-content;
                    margin-left: 2px;
                    margin-bottom: 2px;
                }
            }
        }

        .item-details {
            margin-top: 10px;
            display: grid;
            grid-template-columns: 50% 50%;
            align-items: center;

            .count {
                width: 50%;

                .MuiInputBase-input {
                    min-width: 10px;
                    max-width: 20px;
                    text-align: center;
                    width: min-content;
                }
                .MuiOutlinedInput-adornedEnd {
                    padding-right: 0px;
                }

                .MuiOutlinedInput-adornedStart {
                    padding-left: 0px;
                }
                .MuiOutlinedInput-root {
                    border-radius: 30px !important;
                }

                .PrivateNotchedOutline-root-1 {
                    border-width: 3px !important;
                    border-radius: 30px !important;
                }
            }
            .price {
                text-align: center;
                color: $subtitleColor;

                .simple {
                    .MuiButton-root {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}
