.category-item {
    .item {
        margin-bottom: 10px;
        height: 90px;
        width: min-content;

        text-align: center;
        .icon-button {
            height: 60px;
            width: 60px;
            border-radius: 50%;

            .MuiIconButton-root {
                padding: 0px;
            }
            img {
                margin: 15px;
                height: 30px;
                width: 30px;
            }
        }

        .icon-button1 {
            height: 60px;
            width: 60px;
            border-radius: 50%;

            .MuiIconButton-root {
                padding: 0px;
            }
            img {
                height: 60px;
                width: 60px;
            }
        }
        .name {
            margin-top: 5px;
            font-size: 10px;
            font-weight: 500;
        }
    }
}
