@import "../../shared/core/variables.scss";

@media only screen and (max-width: 1440px) {
    //laptop
    .my-details-container {
        .section {
            .child-1 {
                .form-section {
                    width: 100%;
                    .details {
                        .split-form {
                            .MuiFormControl-root {
                                min-width: 200px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1440px) {
    //desktop
    .my-details-container {
        .section {
            .child-1 {
                .form-section {
                    width: 70%;
                    .details {
                        .split-form {
                            .MuiFormControl-root {
                                min-width: 100% !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.my-details-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto max-content;

    .section {
        padding: 0px 40px;
        height: 100vh;
        overflow: hidden;

        .child-1 {
            margin-bottom: 20px;
            height: 100%;
            overflow: auto;
            padding: 20px;
            padding-bottom: 140px;

            .form-section {
                margin: 20px 0px;

                .title {
                    font-family: $primaryFont !important;
                    font-size: 36px;
                    font-weight: 400 !important;
                    margin-bottom: 20px;
                }

                .details {
                    font-weight: 500;
                    margin-bottom: 20px;

                    .field-title {
                        margin-bottom: 10px;
                        font-weight: 600;
                    }

                    .MuiFormControl-root {
                        min-width: 50%;
                        padding-right: 20px;
                        margin-bottom: 10px;
                    }
                    .MuiOutlinedInput-root {
                        border-radius: 10px;
                    }

                    .MuiOutlinedInput-input {
                        padding: 10px;
                    }
                    .MuiOutlinedInput-root {
                        height: 40px;
                        border-radius: 9px !important;
                        border-width: 10px !important;
                        margin-bottom: 10px;
                    }

                    .half {
                        width: 50%;
                    }

                    .multiline-textbox {
                        .MuiOutlinedInput-root {
                            height: auto !important;
                        }
                    }

                    .split-form {
                        display: grid;
                        grid-template-columns: 50% 50%;

                        :left {
                            padding-right: 20px;
                        }

                        .deliveryZone {
                            display: grid;
                            grid-template-columns: auto min-content;

                            :first-child {
                                padding-right: 5px !important;
                            }
                        }
                    }

                    .delivery {
                        align-items: center;
                    }
                }

                .sub-details {
                    text-align: left;
                    font-size: 12px;
                    font-weight: 600;
                    display: grid;
                    grid-template-columns: 50% 50%;
                    align-items: center;

                    margin: 10px 0px;
                    :last-child {
                        color: $subtitleColor;
                        text-align: right;
                    }
                }
            }
            .row-item {
                background-color: #e4e4e4;
                border-radius: 10px;
                .title {
                    margin-left: 10px;
                    font-size: 14px;
                    font-weight: 700;
                    padding-top: 20px;
                }
                .item-card-small {
                    .MuiPaper-root {
                        height: 130px !important;
                    }

                    .details {
                        .single-item-name {
                            font-size: 12px !important;
                        }
                    }
                }
                margin-bottom: 20px;
            }

            .actions {
                text-align: right;

                .field-title-error {
                    margin-bottom: 10px;
                    font-weight: 600;
                    color:#d8231d
                }
            }

            .simple {
                .MuiButton-root {
                    font-size: 12px;
                    padding: 8px 50px;
                    border-radius: 30px;
                }
            }
        }
    }
}
