@import "../../shared/core/variables.scss";

.container {
    width: 100%;
    display: grid;
    grid-template-columns: auto max-content;
    height: 100vh;
    overflow: hidden;

    .section {
        padding: 0px 40px;
        overflow: scroll;
        .child-1 {
            .row-category {
                margin: 20px 0px;
                padding-left: 20px;
                justify-content: space-between;
                display: grid;
                grid-template-columns: auto auto auto auto auto auto auto auto;
            }
            .row-1 {
                width: 100%;
                display: grid;
                grid-template-columns: 60% 40%;
                margin-top: 20px;
                .left {
                    margin-right: 10px;
                    .title {
                        text-align: start;
                        font-size: 36px;
                        font-weight: 300;
                        font-family: $secondaryFont;
                        padding: 0px 0px 20px 0px;
                    }

                    .subtitle {
                        text-align: start;
                        width: 90%;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                .right {
                    text-align: end;
                    .back {
                        padding: 0px 0px 20px 0px;
                        .back {
                            .MuiButton-root {
                                font-size: 12px;
                                padding: 5px 20px;
                                padding-right: 18px !important;
                                border-radius: 15px !important;
                            }
                        }
                    }
                }
            }

            .row-2 {
                display: grid;
                grid-template-columns: 33% 33% 33%;
            }

            .empty-category {
                color: $subtitleColor;
                font-weight: bold;
                text-align: center;

                .img {
                    margin: 30px 15px;
                    min-height: 60px;

                    .foodIcon {
                        transform: scale(5);
                    }
                }
            }
        }

        .footer {
            margin-top: 20px;
            .bottom {
                .back {
                    visibility: hidden !important;
                }
            }
        }
    }
}
