.container {
    .title {
        font-size: 1.2em;

        img {
            height: 15px;
            margin: 0 20px;
        }

        .filterLabel {
            font-size: 0.8em;
        }
    }

    .deliveryStyle{
        font-size: 1.3em;
        .filterLabel {
            font-size: 0.8em;
        }
        img {
            height: 15px;
            margin: 0 20px;
        }
    }

    table {
        width: 100%;
        border-spacing: 0 10px;
        font-size: 0.9em;

        tr.danger {
            color: red;

            td:first-child {
                border-left: 5px solid red;
            }
        }

        th {
            text-align: left;
            padding: 5px;

            top: 0;
            position: sticky;
            background: #bcbcbc;
        }

        td {
            background: white;
            padding: 10px;
        }

        .actions {
            button {
                margin-right: 10px;
                margin-bottom: 10px;

                img {
                    height: 20px;
                }
            }
        }
    }
}

.dialogBox {
    width: 400px;
    padding: 30px;

    .title {
        font-size: 1.2em;
        font-weight: bold;
    }

    .form {
        margin-top: 10px;
    }

    .tools {
        margin-top: 20px;
        text-align: right;
        flex-direction: row;
    }
    .radioContainer{
        margin-top: 0px;
    }
    .radioTitle{
        font-size: 13px;
        color: rgba(0, 0, 0, 0.54);
        margin-bottom: 0px;
    }
    .title2{
        margin-top: 8px;
        font-size: 1rem;
    }
}
