@import "../core/variables.scss";

.container {
    width: 400px;

    .header {
        padding: 20px;
        font-size: 1.3em;
        border-bottom: 1px solid $borderColor;
    }

    .title {
        padding: 20px;
        border-bottom: 1px solid $borderColor;
        font-size: 1.2em;
    }

    .message {
        padding: 20px;
    }

    .actions {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: right;

        border-top: 1px solid $borderColor;
        padding: 10px;
        text-align: right;

        button {
            margin-left: 10px;
            white-space: nowrap;
            padding: 10px;
            width: 150px;
        }
    }
}