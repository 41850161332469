@import "../../shared/core/variables.scss";

.myProfile-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto max-content;

    .section {
        padding: 0px 40px;
        height: 100vh;
        overflow: hidden;
        padding-bottom: 10px;

        .child {
            overflow: auto;
            height: calc(100vh - 130px);

            .title {
                text-align: start;
                font-size: 36px;
                font-weight: 300;
                font-family: $secondaryFont;
                padding: 20px 0px;
            }

            .changePW {
                font-size: 16px;
                font-weight: bold;
                margin: 20px 0;
            }

            .split-form {
                align-content: left;
                .login-form {
                    text-align: -webkit-center;
                    margin-bottom: 10px;
                    padding: 0px 20px 0px 0px;
                    .form-item {
                        width: 280px;
                        .name {
                            margin: 5px 0px;
                            text-align: left;
                            font-weight: 900;
                            font-size: 10px;
                        }
                        .MuiOutlinedInput-root {
                            height: 40px;
                            border-radius: 9px !important;
                            border-width: 10px !important;
                        }
                        .MuiOutlinedInput-adornedStart {
                            padding-left: 0px;
                        }
                    }
                }
            }

            .success-message {
                color: $successColor;
            }

            .actions {
                padding-top: 20px;
                display: grid;
                grid-template-columns: max-content max-content;

                .simple {
                    .MuiButton-root {
                        font-size: 12px;
                        padding: 5px 70px;
                        border-radius: 15px;
                    }
                }

                .back {
                    .MuiButton-root {
                        font-size: 12px;
                        padding: 5px 20px;
                        padding-right: 18px !important;
                        border-radius: 15px !important;
                    }
                }

                :first-child {
                    margin-right: 10px;
                }
            }
        }

        .points {
            padding: 20px;
            display: grid;
            align-items: center;
            grid-template-columns: min-content auto;

            .icon {
                margin-right: 10px;
            }
        }
    }
}
