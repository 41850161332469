.container {
    display: grid;
    grid-template-columns: auto min-content min-content;
    align-items: center;

    padding: 10px;

    .tools {
        margin-left: 10px;
    }

    img {
        height: 40px;
    }

    .title {
        margin-left: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
    .time {
        margin-left: 1rem;
    }

    button {
        white-space: nowrap;
    }

    background: white;

    .buttonContainer{
        display: flex;
    }
}
