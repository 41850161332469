.bottom {
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    padding-bottom: 10px;

    :last-child {
        text-align: end;
    }
    .terms {
        color: rgba(0, 0, 0, 0.6);
        font-size: 10px;
        font-weight: 400;
        text-align: left;
        margin-top: 5px;
        cursor: pointer !important;
    }

    .back {
        margin-top: 5px;
        visibility: visible;
    }
}
