@import "../../shared/core/variables.scss";

.tnc-container{
    padding-left:3%;
    color: #000;
}
.ul-circle{
    list-style-type:circle !important
}
.section{
    overflow:auto;
    height: 100vh;
    padding-bottom: 50px;
    padding-right: 3%;
}
p{
    // color: #000 !important;
}
li{
    padding-bottom: 5px;
}