.item-card-medium {
    padding: 10px;
    .MuiPaper-root {
        padding: 10px;
        width: 100%;
        height: 270px;
        display: grid;
        border-radius: 15px;
        grid-template-rows: 70% 30%;
    }
    .item-cover {
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .details {
        padding: 0px 10px;

        text-align: start;
        .single-item-name {
            padding: 10px 0px;
            font-weight: 700;
        }

        .single-item-sub-details {
            display: grid;
            grid-template-columns: 50% 50%;

            .item-size {
                padding-top: 10px;
            }

            .single-item-name-right {
                align-self: end;
                text-align: end;
                .simple {
                    .MuiButton-root {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
