.item-card-small {
    padding: 10px;
    display: inline-block;

    .MuiPaper-root {
        width: 130px;
        height: 130px;
        border-radius: 15px;
    }
    .item-cover {
        height: 100%;
        border-radius: 15px;
        background-size: cover;
        background-position: center;
    }

    .details {
        text-align: start;
        padding-left: 5px;
        .single-item-name {
            min-height: 50px;
            padding-top: 5px;
            font-weight: 700;
            max-width: 130px;
        }
    }
}
