@import "./scrollbar.scss";
body {
  font-size: 14px;
  overflow: hidden;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}
.side-menu-container {
  height: 100vh;
}
