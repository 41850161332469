@import "../../shared/core/variables.scss";

.orderPageTwo-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto max-content;

    .section {
        .section-1 {
            .top {
                padding: 0px 40px;
                .top-bar {
                    .search-bar {
                        visibility: hidden;
                    }
                }
            }
        }
        .section-2 {
            width: 100%;
            background-image: url("../../assets/banner_1.png");
            background-size: cover;
            margin: 20px 0px;
            height: 217px;
            display: grid;
            align-self: center;
            justify-content: center;
            padding: 40px 40px;
            color: #fff;

            .title {
                text-align: center;
                font-size: 36px;
                font-weight: 300;
                font-family: $secondaryFont;
                padding: 0px 0px 10px 0px;
            }

            .subtitle {
                text-align-last: end;
                width: 90%;
                font-size: 14px;
                font-weight: 500;
            }

            .MuiPaper-root {
                margin-top: 10px;
                width: 600px;
                border-radius: 20px;
            }

            .MuiOutlinedInput-root {
                width: 600px;
                border-radius: 20px;
            }
        }

        .child-1 {
            padding: 0px 40px;
            .row-category {
                margin-bottom: 20px;
                padding: 0px 20px;
                display: grid;
                grid-template-columns: auto auto auto auto auto auto auto auto;
                .item {
                    height: 90px;
                    width: min-content;

                    text-align: center;
                    .icon-button {
                        height: 60px;
                        width: 60px;
                        background-color: #f5edb9;
                        border-radius: 50%;

                        .MuiIconButton-root {
                            padding: 0px;
                        }
                        img {
                            margin: 15px;
                            height: 30px;
                            width: 30px;
                        }
                    }
                    .name {
                        margin-top: 5px;
                        font-size: 10px;
                        font-weight: 500;
                    }
                }
            }

            .row-item {
                .title {
                    margin-left: 10px;
                    font-size: 14px;
                    font-weight: 700;
                    padding: 20px 0px;
                }
                .specials-list{
                    display: grid;
                    grid-template-columns: 150px 150px 150px 150px 150px;
                }
                margin-bottom: 20px;
            }
        }

        .footer {
            padding: 0px 40px;
            margin-top: 20px;
            .bottom {
                .back {
                    visibility: hidden !important;
                }
            }
        }
    }
}
