@import "../../../../../shared/core/variables.scss";

.formClass{
    width:500px;

    .flexBox{
        display:flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    .inputBox{
        width: 48%;
    }
}