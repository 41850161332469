$primaryFont: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$secondaryFont: "Montserrat", sans-serif;

$subtitleColor: #9c9292;

$primaryColor: #d9211d;

$successColor: #0F9D58;

$borderColor: #ccc;
