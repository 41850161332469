@import "../../shared/core/variables.scss";

.container {
    display: grid;
    grid-template-columns: auto max-content;

    .section {
        padding: 0px 40px;
        height: 100vh;
        overflow: hidden;

        .body {
            margin-top: 30px;
            display: grid;
            justify-content: center;
            align-items: center;
            height: 70%;

            .messageContainer {
                .img {
                    text-align: center;

                    img {
                        width: 20vw;
                    }
                }

                .message {
                    margin-top: 30px;
                    text-align: center;
                    font-size: 1.5em;
                }

                .actions {
                    margin-top: 20px;
                    display: grid;
                    grid-template-columns: auto auto auto;
                }
            }
        }
    }

    button {
        margin-left: 10px;
        white-space: nowrap;
        padding: 10px;
        width: 150px;
        height: 38px;
    }
}
