.container {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    justify-content: end;
    margin: 10px;

    .title {
        font-weight: 600;
    }

    .buttonList {
        display: grid;
        grid-template-columns: min-content min-content min-content min-content min-content min-content;

        .buttonItem {
            margin-left: 5px;
        }
    }
}
