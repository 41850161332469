@import "./style/animated.css";

//For PRinter only css
  

  #thermalPrinter {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 20%;
  }
  
  input,
  textarea {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  button {
    font-size: 2rem;
    padding: 1rem 2rem;
  }
  
  .status-label {
    margin: 1rem 0;
  }
//For PRinter only css
