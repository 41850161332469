@import "../../../shared/core/variables.scss";

.container {
    display: grid;
    grid-template-rows: min-content auto;
    height: 100vh;

    .header {
        display: grid;
        align-items: center;
        font-weight: bold;

        padding: 10px 20px;
        border-bottom: 1px solid #dddddd;
        font-size: 1.2em;

        img {
            height: 20px;
            margin-right: 15px;
        }
    }

    .body {
        display: grid;
        align-items: center;
        justify-content: center;

        .takeOrder {
            padding: 20px 20px;
            width: 800px;
            background: white;

            .title {
                text-align: left;
                font-size: 1.2em;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .searchBar {
                display: grid;
                grid-template-columns: auto 200px;

                button {
                    margin-left: 10px;
                    height: 55px;
                }
            }

            .userInfo {
                padding-top: 20px;

                table {
                    width: 100%;

                    th,
                    td {
                        text-align: left;
                        padding: 5px;
                    }

                    th {
                        width: 100px;
                    }
                }
            }

            .noSearch {
                display: grid;
                align-items: center;
                justify-content: center;
                font-weight: bold;

                height: 100px;
            }

            .actions {
                margin-top: 20px;

                button {
                    margin-right: 10px;
                }
            }
        }
    }

    .dialog {
        .actions {
            text-align: right;
            margin-top: 20px;

            button {
                margin-right: 10px;
            }
        }
    }
}
