.page {
    position: absolute;
    background: #BCBCBC;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .pageContent {
        overflow: auto;
    }
}