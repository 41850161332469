.container {
    .header {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid #DDDDDD;
        font-size: 1.2em;

        span{
            display: flex;
            align-items: center;
        }
        img {
            height: 20px;
            margin-right: 15px;
        }
    }

    .content {
        padding: 40px 5%;

        .title {
            font-weight: bold;
            font-size: 1.2em;
        }

        .orderSummary {
            margin-top: 20px;
        }

        .orderList {
            margin-top: 20px;
        }
    }
}