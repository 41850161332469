@import "../../../shared/core/variables.scss";

.container {
    background: #f0eef2;

    .content {
        font-size: 14px;
        overflow: auto;
        height: calc(100vh - 116px);

        .orders {
            display: grid;
            grid-template-columns: repeat(4, 25%);
            grid-template-rows: minmax(min-content, max-content);
            padding-bottom:10%;
            .orderCard {
                background: white;
                margin: 10px;
                display: flex;
                grid-template-rows: min-content auto min-content;
                cursor: pointer;
                flex-direction: column;
                .header {
                    display: grid;
                    grid-template-columns: min-content auto min-content;
                    align-items: center;
                    padding: 10px;
                    white-space: nowrap;
                    flex: none;
                    &.newOrder {
                        background: #ff4820;
                    }

                    &.ackOrder {
                        background: #ffc42d;
                    }

                    &.cooking {
                        background: #48ff50;
                    }

                    .orderId {
                        white-space: nowrap;
                        font-weight: bold;
                        font-size: "16px";
                    }

                    .timer {
                        white-space: nowrap;
                    }

                    .orderState {
                        margin-left: 10px;

                        .badge {
                            padding: 5px 15px;
                            background: white;
                            display: inline-block;
                            border-radius: 50px;
                        }
                    }
                }

                .orderSummary {
                    padding: 10px;
                    flex: auto;
                    .ordersList {
                        .orderItem {
                            margin-bottom: 5px;
                            .title {
                                font-weight: 600;
                            }

                            .options {
                                margin-left: 10px;

                                .subtitle {
                                    text-align: start;
                                    color: $primaryFont;
                                    width: 90%;
                                    font-size: 12px;
                                    font-weight: 500;
                                    margin-bottom: 4px;
                                }

                                .ingredients {
                                    margin-bottom: 2px;
                                    display: grid;
                                    grid-template-columns: max-content auto;
                                    align-content: center;

                                    .items {
                                        display: flex;
                                        flex-flow: row wrap;
                                        justify-content: flex-start;

                                        .item {
                                            margin-left: 2px;
                                            margin-bottom: 2px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .deliveryZone {
                        margin-bottom: 5px;
                        .title {
                            font-weight: 600;
                        }
                        .subtitle {
                            text-align: start;
                            color: $primaryFont;
                            width: 90%;
                            font-size: 12px;
                            font-weight: 500;
                            margin-bottom: 4px;
                            margin-left: 10px;
                        }
                    }
                }

                .footer {
                    border-top: 1px solid #d6d6d6;
                    padding: 10px;
                    display: grid;
                    grid-template-columns: auto max-content;
                    align-items: center;
                    font-size: 12px;
                    font-weight: 600;
                    flex: none;
                    &.warn {
                        background: #D32F2F;
                        color: white;
                    }

                    .countdowns {
                        display: grid;
                        grid-template-columns: max-content;
                        align-items: center;

                        .countdown {
                            display: grid;
                            align-items: center;
                            white-space: nowrap;
                            grid-template-columns: auto auto;

                            img {
                                margin-right: 5px;
                            }
                            
                        }

                        img {
                            height: 15px;
                        }
                    }
                    .listIcon{
                        vertical-align: middle;
                        width: 15px;
                        margin-left: 10px;
                    }
                }
            }
        }

        .emptyBasket {
            display: grid;
            align-items: center;
            color: $subtitleColor;
            font-weight: bold;
            font-size: 17px;
            text-align: center;

            .img {
                padding: 30px 15px;

                img {
                    width: 100px;
                }
            }
        }
    }
}

