@import "../../shared/core/variables.scss";

.cover {
  height: 100vh;
  background-image: url("../../assets/bg.png");
  background-size: cover;

  .section {
    padding: 80px 0px;
    margin: 0 auto;
    align-self: center;
    height: 100%;
    width: min-content;
    text-align: center;
    color: white;
    font-family: $secondaryFont;

    img {
      height: 70px;
    }

    .title {
      margin: 10px 0px;
      padding: 0px 80px;
      width: max-content;
      font-family: $primaryFont !important;
      font-size: 36px;
      font-weight: 300;
    }

    .subtitle {
      color: rgba(255, 255, 255, 0.6);
      padding: 0px 120px;
      font-size: 10px;
      font-weight: 100;
    }

    .items {
      margin: 30px auto !important;
      height: min-content;
      .item {
        margin: 5px 30px;
        img {
          height: 40px !important;
        }

        .tag {
          width: min-content;
          text-align: center;
          font-weight: 300;
          margin-top: 5px;
        }
      }
    }

    .login {
      margin-bottom: 8px;
      .MuiButton-root {
        padding: 5px 70px;
        border-radius: 9px !important;
      }
    }

    .options {
      font-size: 14px;
      font-weight: 200;
      margin-bottom: 20px;

      p {
        margin-block-start: 4px;
        margin-block-end: 4px;
      }

      .skip {
        cursor: pointer;
      }
    }

    .terms {
      color: rgba(255, 255, 255, 0.6);
      font-size: 10px;
      font-weight: 400;
      text-align: left;
      margin-top: 5px;
      cursor: pointer !important;
    }
  }

  .MuiDivider-root {
    background-color: rgba(255, 255, 255, 0.6) !important;
    margin: 0 !important;
  }
}

#signup-button {
  margin-left: 4px;
  cursor: pointer;
  color: #d9211d !important;
}
