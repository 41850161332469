@import "../../shared/core/variables.scss";

.search-result-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto max-content;
    height: 100vh;
    .section {
        padding: 0px 40px;
        overflow: auto;
        .child-1 {
            .row-1 {
                width: 100%;
                display: grid;
                grid-template-columns: 60% 40%;
                margin-top: 20px;
                .left {
                    margin-right: 10px;
                    .title {
                        text-align: start;
                        font-size: 36px;
                        font-weight: 300;
                        font-family: $secondaryFont;
                        padding: 0px 0px 20px 0px;
                    }

                    .subtitle {
                        text-align: start;
                        width: 90%;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                .right {
                    text-align: end;
                    .back {
                        padding: 0px 0px 20px 0px;
                        .back {
                            .MuiButton-root {
                                font-size: 12px;
                                padding: 5px 20px;
                                padding-right: 18px !important;
                                border-radius: 15px !important;
                            }
                        }
                    }
                }
            }

            .row-2 {
                display: grid;
                grid-template-columns: 33% 33% 33%;
            }
        }

        .footer {
            margin-top: 20px;
            .bottom {
                .back {
                    visibility: hidden !important;
                }
            }
        }
    }
}
