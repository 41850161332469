@import "../core/variables.scss";

.side-menu {
    display: grid;
    grid-template-rows: min-content auto min-content;
    width: 350px;
    height: 100%;
    min-height: 100vh;
    background-color: #f0eef2;
    padding: 0px 10px;
    position: relative;

    .title-container {
        display: grid;
        grid-template-columns: max-content auto;
        align-items: center;
        .title {
            font-family: $secondaryFont;
            font-size: 20px;
            padding: 20px 0px;
        }

        :last-child {
            text-align: right;
        }
    }

    .cart-items {
        padding-right: 10px;
        // display: grid;
        overflow: auto;
    }

    .cart-bottom {
        .sub-total {
            width: 100%;
            display: grid;
            grid-template-columns: 50% 50%;
            text-align: start;
            font-weight: 800;
            font-size: 12px;
            padding-bottom: 10px;
            :last-child {
                text-align: end !important;
            }

            .promoName{
                text-align: left !important;
                font-size: smaller;
                color: grey;
            }
        }
        .checkout {
            text-align: center;
            margin-bottom: 20px;
            .simple {
                .MuiButton-root {
                    width: 100%;
                    font-size: 12px !important;
                    padding: 10px 90px !important;
                    border-radius: 30px !important;
                }
            }
        }
    }

    .empty-basket {
        color: $subtitleColor;
        font-weight: bold;
        text-align: center;

        .img {
            padding: 30px 15px;

            img {
                width: 100px;
            }
        }
    }
}
