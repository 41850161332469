@import "../../../shared/core/variables.scss";

.container {
    height: 100vh;

    .header {
        display: grid;
        align-items: center;
        grid-template-columns: min-content min-content;
        font-weight: bold;

        padding: 10px 20px;
        border-bottom: 1px solid #dddddd;
        font-size: 1.2em;
        white-space: nowrap;

        img {
            height: 20px;
            margin-right: 15px;
        }
    }

    .content {
        padding: 20px 5%;

        .title {
            font-weight: bold;
            font-size: 1.5em;
            margin-bottom: 20px;
        }

        .orderDetails {
            display: grid;
            grid-template-columns: 40% 60%;
            background: white;
            padding: 20px;

            .orderInformation {
                border: 1px solid #dfdfdf;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 10px 0;
                padding: 10px;


                table {
                    width: 100%;

                    .title {
                        font-size: 1.2em;
                    }

                    th,
                    td {
                        padding: 5px;
                    }

                    th {
                        text-align: left;
                    }
                }
            }

            .cart {
                border: 1px solid #dfdfdf;

                margin: 10px 0;

                .title {
                    border-bottom: 1px solid #dfdfdf;
                    padding: 10px;
                }

                .orderItems {
                    height: calc(80vh - 200px);
                    overflow: auto;

                    .orderItem {
                        border: 1px solid #dfdfdf;
                        padding: 10px;
                        margin: 5px;
                        display: grid;
                        grid-template-columns: 100px auto min-content;

                        .image {
                            height: 100px;
                            background-size: cover;
                            background-position: center;
                        }

                        .details {
                            padding: 10px;

                            .label {
                            }

                            .subLabel {
                            }

                            .restaurant {
                                font-size: 1.2em;
                                font-weight: bold;
                                color: $subtitleColor;
                            }

                            .ingredients {
                                margin-bottom: 2px;
                                display: grid;
                                grid-template-columns: max-content auto;

                                .items {
                                    display: flex;
                                    flex-flow: row wrap;
                                    justify-content: flex-start;

                                    .chip {
                                        width: fit-content;
                                        margin-left: 2px;
                                        margin-bottom: 2px;
                                    }
                                }
                            }
                        }

                        .price {
                            align-self: center;
                        }
                    }
                }

                .footer {
                    border-top: 1px solid #dfdfdf;
                    padding: 20px;
                    .item {
                        display: grid;
                        grid-template-columns: auto min-content;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}


