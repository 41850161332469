.container {
    width: 220px;
    background: white;
    display: grid;
    grid-template-rows: min-content auto;

    .header {
        margin-top: 20px;
        text-align: center;

        img {
            width: 50%;
        }

        .title {
            font-weight: bold;
        }
    }



    .navigation {
        margin-top: 50px;
        margin-bottom: 20px;
        display: grid;
        grid-template-rows: auto min-content;

        .navItem {
            cursor: pointer;

            padding: 10px 20px;
            margin-bottom: 5px;
            display: grid;
            grid-template-columns: min-content min-content;
            align-items: center;
            white-space: nowrap;

            img {
                height: 25px;
                margin-right: 20px;
            }

            &:hover {
                border-right: 5px solid red;
            }
        }

        .active {
            .navItem {
                border-right: 5px solid red;
            }
        }
    }
}

.timeRangeFlex{
    display: flex;
    justify-content: space-between;
}


//Driver check in 

.listContainer{
    flex: 1;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
}