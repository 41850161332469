@import "../../shared/core/variables.scss";

.order-list-container {
    .top-bar {
        padding: 0px 20px;
    }
    .section {
        background-color: #f0eef2;
        width: 100%;
        height: calc(100vh-80px);
        padding: 0px 40px;

        .back-button {
            padding: 20px 0;

            .MuiButton-root {
                font-size: 12px;
                padding: 5px 20px;
                padding-right: 18px !important;
                border-radius: 15px !important;
            }
        }

        .table-section {
            width: 100%;
            margin-bottom: 20px;

            .MuiTableCell-head {
                color: $primaryColor;
            }

            .MuiTableContainer-root {
                height: 65vh;
                overflow-x: auto;
            }

            .MuiTableCell-root {
                padding: 10px 16px !important;
            }

            .head-item {
                display: grid;
                grid-template-columns: min-content max-content;
                align-items: center;

                .title {
                    padding-left: 5px;
                }

                .order-title {
                    padding-left: 5px;
                    font-size: 18px !important;
                }
            }
        }
    }
}

#head {
    font-weight: 800;

    .head-item {
        display: grid;
        grid-template-columns: min-content max-content;
        align-items: center;

        .MuiSvgIcon-root {
            width: 0px;
            color: $primaryColor;
            visibility: hidden;
        }
    }
}

#error-row {
    border: solid;
    border-width: 2px;
    border-color: $primaryColor;

    .head-item {
        .MuiSvgIcon-root {
            width: auto !important;
            visibility: visible !important;
        }
    }
}

tr {
    cursor: pointer;
}
.bold{
    font-weight: 600 !important;
}


.tblPagination{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .pageIndex{
        font-size: 16px;
        margin-right: 1rem;
    }
}