.container {
    .title{
        font-size: 1.3em;
        .filterLabel {
            font-size: 0.8em;
        }
        img {
            height: 15px;
            margin: 0 20px;
        }
        .grpBtn{
            margin-left: 1rem;
            border-radius: 50px;
            padding:0.2rem 1rem;
        }
    }

    table {
        width: 100%;
        border-spacing: 0 10px;
        font-size: 0.9em;

        tr.danger {
            color: red;

            td:first-child {
                border-left: 5px solid red;
            }
        }

        th {
            text-align: left;
            padding: 5px;

            top: 0;
            position: sticky;
            background: #bcbcbc;
        }

        td {
            background: white;
            padding: 10px;
        }

        .actions {
            button {
                img {
                    height: 20px;
                }
            }
        }
    }
}

.dialogBox {
    width: 400px;
    padding: 30px;

    .title {
        font-size: 1.2em;
        font-weight: bold;
    }

    .form {
        margin-top: 10px;
    }

    .tools {
        margin-top: 20px;
        text-align: right;
        flex-direction: row;
    }
    .radioContainer{
        margin-top: 0px;
    }
    .radioTitle{
        font-size: 13px;
        color: rgba(0, 0, 0, 0.54);
        margin-bottom: 0px;
    }
    .title2{
        margin-top: 8px;
        font-size: 1rem;
    }
}

.modalContainer{
    .subjectText{
        -webkit-font-smoothing: antialiased;
        font-family: "Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
        font-size: 1.375rem;
        font-variant-ligatures: no-contextual;
        color: #1f1f1f;
        font-weight: 400;
        line-height: normal;
    }
    .customerText{
        font-size: 1rem;
        
    }
    .messageText{
        font-size: 16px;
        line-height: 24px;
    }
    .replyBox{
        margin-top:2rem
    }
    .msgHead{
        font-size: 16px;
        line-height: 24px;
        margin-top: 1rem;
    }
    .date{
        font-size: 12px;
        font-weight: 600;
        color: #808080;
    }
}
    
.tblPagination{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .pageIndex{
        font-size: 16px;
        margin-right: 1rem;
    }
}