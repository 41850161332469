.dialogOverlay {
    position: absolute;

    right: 0;
    left: 0;
    top: 0;
    bottom: 0;

    background: rgba($color: #000000, $alpha: 0.5);

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;

    align-items: center;
    justify-items: center;

    .dialogContent {
        background: white;
    }
}