@import "../../shared/core/variables.scss";

.orderPageOne-container {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: auto max-content;
    overflow: hidden;
    .section {
        padding: 0px 40px;
        height: 100vh;
        .child-1 {
            height: calc(100vh - 165px);
            overflow: auto;
            .row-1 {
                width: 100%;
                background-image: url("../../assets/banner_2.png");
                background-size: cover;
                margin: 20px 0px;
                height: 217px;
                display: grid;
                align-self: center;
                padding: 50px 40px;

                .title {
                    text-align: start;
                    font-size: 30px;
                    font-weight: 300;
                    font-family: $secondaryFont;
                    padding: 0px 0px 10px 0px;
                }

                .subtitle {
                    text-align: start;
                    width: 90%;
                    font-size: 14px;
                    font-weight: 500;
                }

                .simple {
                    margin-top: 10px;
                    .MuiButton-root {
                        font-size: 12px;
                        padding: 2px 20px;
                        border-radius: 15px;
                    }
                }
            }

            .restaurants {
                .restaurant-item {
                    width: fit-content;
                    display: inline-block;
                    padding: 0 15px;

                    .item {
                        display: grid;
                        grid-template-rows: auto auto;
                        margin: 20px 0px;
                        width: fit-content;
                        text-align: center;

                        img {
                            height: 40px;
                        }

                        .restaurant-name {
                            padding-top: 5px;
                            font-size: 15px;
                            font-weight: 600;
                        }
                    }
                }
            }

            .row-category {
                margin-bottom: 20px;
                padding: 0px 20px;
                display: grid;
                grid-template-columns: auto auto auto auto auto auto auto auto;
            }

            .row-item {
                .title {
                    margin-left: 10px;
                    font-size: 18px;
                    font-weight: 700;
                    padding: 10px 0px;
                }

                .no-products {
                    margin-left: 10px;
                    font-weight: bold;
                    color: $subtitleColor;
                }

                .specials-list {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                }

                margin-bottom: 20px;
            }
        }

        .footer {
            margin-top: 20px;
            .bottom {
                .back {
                    visibility: hidden !important;
                }
            }
        }
    }
}
