.orderSummary {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));

    .card {
        padding: 15px;
        margin: 5px;
        border: 1px solid;
        cursor: pointer;

        text-align: center;

        background: #fff;
        border: none;
        border-bottom: 5px solid transparent;

        .value {
            font-size: 2em;
        }

        .label {

        }

        &:hover {
            border-bottom: 5px solid #d9211d;
        }

        &.active {
            border-bottom: 5px solid #d9211d;
        }
    }
}